<template>
  <trazo-off-canvas
    :isOpen="isOpen"
    id="modal-form-enterprise"
    :selectorToggle="selectorToggle"
    v-on:close="closeModal"
    :title="textModal"
  >
    <template v-slot:header-buttons>
      <b-button size="sm" variant="light" @click="closeModal">
        <b-icon icon="arrow-counterclockwise" />
      </b-button>
      <b-button size="sm" variant="light" @click="onSubmit">
        {{ sending ? "Guardando..." : "Guardar" }}
      </b-button>
    </template>
    <b-row class="justify-content-center">
      <form class="full" id="formSubproject" ref="formSubproject">
        <trazo-form-input
          id="input-commercial-name"
          v-model="commercial_name"
          label="Nombre comercial"
          name="commercial_name"
          :required="true"
          labelPosition="top"
          type="text"
        />
        <trazo-form-input
          id="input-ruc"
          v-model="ruc"
          label="RUC"
          name="ruc"
          :required="true"
          labelPosition="top"
          type="text"
        />
        <trazo-form-input
          id="input-name"
          v-model="name"
          name="name"
          label="Razón Social"
          :required="true"
          labelPosition="top"
          type="text"
        />
        <trazo-form-input
          id="input-address"
          v-model="address"
          label="Dirección fiscal"
          name="address"
          :required="true"
          labelPosition="top"
          type="text"
        />
        <trazo-form-label-slot label="Firma Administrador" labelPosition="top">
          <trazo-image-crop
            v-on:showModal="onShowModal"
            v-on:selectImage="selectPhoto"
          />
          <img
            class="mt-3"
            style="max-width: 300px;"
            v-if="imageToSend && !showImage"
            :src="imageToSend.dataUrl"
          />
          <img
            class="mt-3"
            v-if="sign && !imageToSend && !showImage"
            :src="sign"
          />
        </trazo-form-label-slot>
      </form>
    </b-row>
  </trazo-off-canvas>
</template>

<script>
import Vue from "vue";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";
import TrazoFormInput from "@/view/content/TrazoFormInput";
import TrazoOffCanvas from "@/view/content/TrazoOffCanvas";
import TrazoImageCrop from "@/view/content/TrazoImageCrop";
import TrazoFormLabelSlot from "@/view/content/TrazoFormLabelSlot";
import ImageCompressor from "compressorjs";

function initialState() {
  return {
    imageToSend: null,
    sign: null,
    sending: false,
    textModal: "NUEVA EMPRESA",
    name: "",
    commercial_name: "",
    address: "",
    ruc: "",
    id: "",
    errors: [],
    edit: false,
    showImage: false
  };
}

export default Vue.component("modal-form-enterprise", {
  data() {
    return initialState();
  },
  props: ["objForm", "isOpen", "selectorToggle"],
  watch: {
    objForm(newValue, oldValue) {
      if (newValue != oldValue) {
        this.setData();
      }
    }
  },
  methods: {
    onShowModal(event) {
      this.showImage = event;
    },
    closeModal() {
      this.resetForm();
      Object.assign(this.$data, initialState());
      this.$emit("close");
    },
    resetForm() {
      this.setupValidation();
      this.fv.off("core.form.valid", () => {
      });
      this.fv.off("core.form.invalid", () => {
      });
      this.fv.resetForm();
    },
    onSubmit() {
      this.resetForm();
      this.fv.validate();
      this.fv.on("core.form.valid", () => {
        const saveFunction = this.id ? ApiService.patch : ApiService.post;
        let data = new FormData();
        if (this.imageToSend) {
          data.append("sign", this.imageToSend.blob, this.imageToSend.name);
        }
        data.append("name", this.name.trim());
        data.append("ruc", this.ruc.trim());
        data.append("commercial_name", this.commercial_name.trim());
        data.append("address", this.address.trim());
        this.sending = true;
        saveFunction(`api/enterprise/${this.id ? this.id + "/" : ""}`, data, {
          headers: {
            "Content-Type": " multipart/form-data"
          }
        })
          .then(({ data }) => {
            this.$emit("toast", {
              message: `Se ha ${
                this.edit ? "editado" : "creado"
              } el item correctamente`,
              object: {
                title: `Éxito`,
                variant: "success",
                solid: true
              }
            });
            this.$emit(this.edit ? "updated" : "created", data);
            this.closeModal();
            this.sending = false;
          })
          .catch(({ response: { data } }) => {
            this.sending = false;
            Object.entries(data).forEach(object => {
              this.errors.push(object[1].join(" - "));
              this.fv.updateFieldStatus(object[0], "Invalid");
            });
            this.$emit("toast", {
              message: "Ha ocurrido un error",
              object: {
                title: `Error`,
                variant: "danger",
                solid: true
              }
            });
          });
      });
      this.fv.on("core.form.invalid", () => {
        this.$emit("toast", {
          message: "Por favor complete los campos correctamente.",
          object: {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        });
      });
    },
    setData() {
      if (!this.custom_lodash.isEmpty(this.objForm)) {
        this.id = this.objForm.id;
        this.name = this.objForm.name;
        this.sign = this.objForm.sign;
        this.ruc = this.objForm.ruc;
        this.commercial_name = this.objForm.commercial_name;
        this.address = this.objForm.address;
        this.textModal = "EDITAR EMPRESA";
        this.edit = true;
      }
    },
    setupValidation() {
      const formSubproject = KTUtil.getById("formSubproject");

      this.fv = formValidation(formSubproject, {
        fields: {
          name: {
            validators: {
              notEmpty: {
                message: "La razón social es obligatoria"
              }
            }
          },
          ruc: {
            validators: {
              notEmpty: {
                message: "El RUC es obligatorio"
              },
              stringLength: {
                min: 8,
                max: 11,
                message: "Ingrese un RUC correcto"
              }
            }
          },
          address: {
            validators: {
              notEmpty: {
                message: "La dirección fiscal es obligatoria"
              }
            }
          },
          commercial_name: {
            validators: {
              notEmpty: {
                message: "El nombre comercial es obligatorio"
              }
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    },
    selectPhoto(image) {
      // let data = new FormData();
      this.imageToSend = image;
      new ImageCompressor(image.blob, {
        convertSize: Infinity,
        maxWidth: 300,
        maxHeight: 100,
        minWidth: 300,
        minHeight: 100,
        quality: 0.6,

        success: result => {
          console.log("new file", result);
          this.imageToSend.blob = result;
        },
        error(err) {
          console.log(err.message);
        }
      });
    }
  },
  mounted() {
    this.setData();
    this.setupValidation();
  },
  components: {
    TrazoOffCanvas,
    TrazoFormInput,
    TrazoImageCrop,
    TrazoFormLabelSlot
  }
});
</script>
