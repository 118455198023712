<template>
  <div>
    <portal to="subheader_actions">
      <b-button v-b-modal.modal-form-enterprise @click="openModal" class="mr-2">
        <b-icon icon="plus" aria-label="Help"></b-icon>
        Nuevo
      </b-button>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div>
              <b-table
                head-variant="dark"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(actions)="data">
                  <div class="h5 mb-0">
                    <b-button
                      size="sm"
                      variant="light"
                      v-b-modal.modal-form-enterprise
                      @click="editItem(data)"
                    >
                      <b-icon icon="pencil-square"></b-icon>
                    </b-button>
                    <b-button
                      v-b-modal.modal-employees
                      @click="goToEmployeesManagement(data)"
                      size="sm"
                      variant="light"
                      alt="Asignación de Empleados"
                    >
                      <b-icon icon="person-fill"></b-icon>
                    </b-button>
                  </div>
                </template>
                <template #cell(is_active)="data">
                  <b-form-checkbox
                    v-model="data.item.is_active"
                    name="check-button"
                    switch
                    size="lg"
                    @change="
                      updateStatus(data.item.is_active, data.item.id)
                    "
                  >
                  </b-form-checkbox>
                </template>
              </b-table>
            </div>
          </b-card-text>
          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </template>
        </b-card>
      </div>
    </div>
    <modal-form-enterprise
      :isOpen="showModal"
      v-show="loaded"
      v-on:close="
        showModal = false;
        currentObject = null;
      "
      :objForm="currentObject"
      v-on:toast="openToast"
      v-on:updated="onUpdated"
      v-on:created="onCreated"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ModalFormEnterprise from "./ModalFormEnterprise";

export default {
  data() {
    return {
      showModal: false,
      currentObject: {},
      currentPage: 1,
      perPage: 12,
      items: [],
      fields: [
        { key: "actions", label: "Acciones" },
        {
          key: "is_active",
          sortable: true,
          label: "¿Activa?"
        },
        {
          key: "name",
          sortable: true,
          label: "Razón Social"
        },
        {
          key: "ruc",
          sortable: true,
          label: "RUC"
        }
      ],
      users: [],
      roles: [],
      showModalEmployees: false,
      loaded: false
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  methods: {
    updateStatus(value, id) {
      console.log(value, id);
      ApiService.patch(`api/enterprise/${id}/`, { is_active: value }).then(res =>
        console.log(res)
      );
    },
    getUsers() {
      ApiService.get("api/user").then(({ data }) => {
        this.users = data;
      });
    },
    getRoles() {
      ApiService.get("api/role").then(({ data }) => {
        this.roles = data.roles;
      });
    },
    openToast({ message, object }) {
      this.$bvToast.toast(message, object);
    },
    listItems() {
      ApiService.get("api/enterprise").then(({ data }) => {
        this.items = data;
      });
    },
    openModal() {
      if (!this.loaded)
        setTimeout(() => {
          this.showModal = true;
        }, 50);
      else this.showModal = true;
      this.loaded = true;
    },
    editItem(data) {
      this.currentObject = data.item;
      this.openModal();
    },
    onCreated(object) {
      this.items.unshift(object);
    },
    onUpdated(object) {
      this.items = this.items.map((data) => {
        const { id } = data;
        if (id === object.id) {
          return object;
        }
        return data;
      });
    },
    goToEmployeesManagement({ item }) {
      this.$router.push(`/human-resources/enterprise/${item.id}/employees/`);
    }
  },
  created() {
    this.listItems();
    this.getUsers();
    this.getRoles();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mantenimientos" },
      { title: "Empresa" }
    ]);
  },
  components: {
    ModalFormEnterprise
  }
};
</script>
