<template>
  <div :id="id" :ref="id" :class="[ (formulario && abierto) ? contentAreaClass:'',{'offcanvas-left trazo-offcanvas-formulario':formulario},{'offcanvas-right trazo-offcanvas-filtro':filtro}]" class="offcanvas " >
    <!--begin::Header-->
    <div class="offcanvas-header">
      <div :class="[ !filtro ? 'closeWrap ':'closeWrap-filtro espaciado']">
        <span v-if="filtro" class="tituloFiltro" >FILTRAR POR</span>
        <div v-if="formulario">
          <b-button variant="light" @click="closeOffcanvas">
            <b-icon icon="x" />
          </b-button>
        </div>
        <div v-else>
          <slot name="header-buttons-filter" />
        </div>
      </div>
      <div v-if="formulario"
        class="d-flex justify-content-between pt-3 pb-3 pl-4 pr-4 align-items-center"
      >
        <div>{{ title }}</div>
        <div>
          <slot name="header-buttons" />
        </div>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content scroll pl-10 pr-10 pt-5"
      style="max-height: 90vh; position: relative"
    >
      <slot></slot>
    </perfect-scrollbar>
    <!--end::Content-->
    <div v-if="filtro" class="offcanvas-footer">
      <div>
        <slot name="footer-buttons-filter" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.offcanvas-header {
  .closeWrap {
    height: 35px;
    width: 100%;
    background: #00000029;
  }
  .closeWrap-filtro {
    height: 45px ;
    width: 100% ;
    background: #151515f0 ;
    color: #FFFFFF;
  }
  button {
    color: #3f4254;
    background: transparent;
    border: none;
    border-radius: 0;
  }
}

.offcanvas-footer {
    height: 75px;
    width: 100%;
    background: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  button {
    margin-top: 18px;
  }
}

.trazo-offcanvas-formulario {
  //top: -5.5vh;
  //position: absolute;
  z-index: 97;
  min-height: 100vh;
}

.offcanvas{
  //left: auto !important;
}

.trazo-offcanvas-filtro {
  //position: absolute;
  z-index: 97;
  max-height: 530px !important;
  .offcanvas-content{
    padding: 2rem !important;
    max-height: 480px !important;
  }
}

.tituloFiltro{
  margin-left: 0.9rem;
  margin-top: 0.7rem;
  font-size: 17px;
  letter-spacing: 2px;
  font-weight: 600;

}

.espaciado {
  display: flex;
  justify-content: space-between;
}

</style>

<style>
.offcanvas-overlay {
  z-index: 96;
  background: rgba(0, 0, 0, 0.2) !important;
}
</style>

<script>

import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import KTUtil from "@/assets/js/components/util";
import { mapGetters } from "vuex";
import {UPDATE_SIDEBAR_WIDTH} from "@/core/services/store/sidebarWidth.js";

export default {
  name: "trazo-off-canvas",
  props: {
    title: { type: String, default: "Trazo Offcanvas" },
    id: { type: String, default: "" },
    selectorToggle: { type: String, default: "trazo_offcanvas_toggle" },
    selectorClose: { type: String, default: "trazo_offcanvas_close" },
    isOpen: { type: Boolean, default: false },
    formulario: { type: Boolean, default: true },
    filtro: { type: Boolean, default: false },
  },
  data() {
    return {
      layout: null,
      element: null,
      abierto: false,
    };
  },
  computed: {
    ...mapGetters(["isSidebarWidth"]),
    contentAreaClass() {
      if (this.isSidebarWidth == 'reduced') return 'content-area-reduced';
      else if (this.isSidebarWidth == 'expand') return 'content-area-expand';
      else if (this.isSidebarWidth == 'close') return 'content-area-expand';
      return 'content-area-expand';
    },
  },
  watch: {
    isOpen: function(newValue) {
      if (newValue === false) {
        if (this.layout) {
          this.abierto = false; 
          this.layout.hide();
        }
      } else {
        this.abierto = true; 
        this.layout.show();
      }
    }
  },
  mounted() {
    this.element = this.$refs[this.id];
    this.init();
  },
  methods: {
    init() {
      this.layout = new KTOffcanvas(this.id, {
        overlay: true,
        baseClass: "offcanvas",
        placement: "left",
        closeBy: this.selectorClose,
        toggleBy: this.selectorToggle
      });

      this.layout.on("afterHide", () => {
        this.$store.dispatch(UPDATE_SIDEBAR_WIDTH, 'close');
        console.log("se cierra=>",this.contentAreaClass);
        this.$emit("close");
      });

      var header = KTUtil.find(this.element, ".offcanvas-header");
      var content = KTUtil.find(this.element, ".offcanvas-content");

      KTUtil.scrollInit(content, {
        disableForMobile: true,
        resetHeightOnDestroy: true,
        handleWindowResize: true,
        height: () => {
          var height = parseInt(KTUtil.getViewPort().height);

          if (header) {
            height = height - parseInt(KTUtil.actualHeight(header));
            height = height - parseInt(KTUtil.css(header, "marginTop"));
            height = height - parseInt(KTUtil.css(header, "marginBottom"));
          }

          if (content) {
            height = height - parseInt(KTUtil.css(content, "marginTop"));
            height = height - parseInt(KTUtil.css(content, "marginBottom"));
          }

          height = height - parseInt(KTUtil.css(this.element, "paddingTop"));
          height = height - parseInt(KTUtil.css(this.element, "paddingBottom"));

          height = height - 2;
          return height;
        }
      });
    },
    closeOffcanvas() {
      this.layout.hide();
    }
  },

};

</script>
